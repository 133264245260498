<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="$router.push({name: 'home-orders-rental-sales-list'})"
        >
          {{ $t('Back to List') }}
        </b-button>
        <div
          v-for="button in leftSideButtons"
          :key="button.title"
        >
          <b-overlay
            :show="loading.actionKey === button.actionKey && loading.progress"
            rounded
            opacity="0.6"
            spinner-small
            :spinner-variant="button.variant"
            class="d-inline-block"
          >
            <!--
              :disabled="loading.progress"-->
            <b-button
              disabled
              :variant="button.variant"
              :class="button.classes"
              @click="submitAction(button.actionKey)"
            >
              <feather-icon
                :v-if="button.icon"
                :icon="button.icon ? button.icon.path : ''"
                :size="button.icon ? button.icon.size : ''"
              />
              {{ $t(button.title) }}
            </b-button>
          </b-overlay>
        </div>
      </div>
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <div
          v-for="button in rightSideButtons"
          :key="button.title"
        >
          <b-overlay
            :show="loading.actionKey === button.actionKey && loading.progress"
            rounded
            opacity="0.6"
            spinner-small
            :spinner-variant="button.variant"
            class="d-inline-block"
          >
            <!--
              :disabled="loading.progress"-->
            <b-button
              disabled
              :variant="button.variant"
              :class="button.classes"
              @click="submitAction(button.actionKey)"
            >
              <feather-icon
                :v-if="button.icon"
                :icon="button.icon ? button.icon.path : ''"
                :size="button.icon ? button.icon.size : ''"
              />
              {{ $t(button.title) }}
            </b-button>
          </b-overlay>
        </div>

      </div>
    </div>
  </portal>
</template>
<script>
import { getValueFromGivenObjectByKey } from '@core/utils/utils'
import { BButton, BOverlay } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import html2canvas from 'html2canvas'
import config from './config'
import mainConfig from '../../config'

export default {
  name: 'QuoteFooterButtons',
  components: {
    BOverlay,
    BButton,
  },
  data() {
    return {
      loading: {
        actionKey: '',
        progress: false,
      },
    }
  },
  computed: {
    order() {
      return this.$store.state[this.MODULE_NAME].order
    },
    total_order_notes() {
      return this.$store.state[this.MODULE_NAME].totalsForm.total_order_notes
    },
    internal_shipping_notes() {
      return this.$store.state[this.MODULE_NAME].dispatchAndReturnForm.internal_shipping_notes
    },
  },
  methods: {
    onClear() {
      for (let i = 0; i < this.storeMutationsList.length; i++) {
        this.$store.commit(`${this.MODULE_NAME}/${this.storeMutationsList[i].mutation}`, this.storeMutationsList[i].payload)
      }
    },
    redirectToRFQSend(id, action) {
      if (id) {
        if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE) {
          this.$router.push({
            name: 'draft-quote-information',
            params: { id },
          })
        } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE) {
          this.$router.push({
            name: 'approve-quote-information',
            params: { id },
          })
        } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID) {
          this.$router.push({
            name: 'home-orders-rental-sales',
          })
        } else if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS) {
          window.location.reload()
        } else {
          window.location.reload()
        }
      } else {
        this.$router.push({
          name: 'home-orders-rental-sales',
        })
      }
    },
    printCanvas() {
      const appContentEl = document.getElementById('app-content')

      html2canvas(appContentEl).then(canvas => {
        const printWindow = window.open('', '_blank')
        const printDocument = printWindow.document

        printDocument.write(`
        <html>
          <head>
            <title>Print Canvas</title>
            <style>
              body { margin: 0 15%;height: 100%; }
              canvas { height: 100%; width: 100%;}
            </style>
          </head>
          <body>
            <canvas id="printCanvas" style="display:block;"></canvas>
          </body>
        </html>
      `)

        const printCanvas = printDocument.getElementById('printCanvas')
        printCanvas.width = canvas.width
        printCanvas.height = canvas.height

        const printCtx = printCanvas.getContext('2d')
        printCtx.drawImage(canvas, 0, 0)

        printWindow.print()
        printWindow.close()
      })
    },
    submitAction(action) {
      if (action === this.DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT) {
        this.printCanvas()
        return
      }
      const paramId = this.$route.params.id
      this.error = {}
      this.loading = {
        actionKey: action,
        progress: true,
      }

      this.sendNotification(this, {
        id: paramId,
        action,
        total_order_notes: this.total_order_notes,
        internal_shipping_notes: this.internal_shipping_notes,
      }, `${this.MODULE_NAME}/update`, paramId)
        .then(({ data }) => {
          const { id } = data.data
          this.redirectToRFQSend(id, action)
          this.onClear()
        })
        .catch(err => {
          this.$store.commit(`${this.MODULE_NAME}/SET_ON_ERROR`, err.response?.data)
          this.error = err.response?.data
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.error.message,
            },
          })
        }).finally(() => {
          this.loading = {
            actionKey: '',
            progress: false,
          }
        })
    },
  },
  setup() {
    const DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT = 'print'
    const {
      EVENT_INFORMATION_TITLE,
      eventInformationObjectForRender,
    } = config()

    const {
      MODULE_NAME,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE,
      storeMutationsList,
    } = mainConfig()

    const rightSideButtons = [
      {
        title: 'Print',
        actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
        classes: 'font-medium-1 font-weight-bold px-3',
        icon: {
          size: '16',
          path: 'LPrintIcon',
        },
      },
      {
        title: 'Release Assets',
        actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS,
        classes: 'font-medium-1 font-weight-bold px-3 bgRed',
        variant: 'danger',
        icon: null,
      },
      {
        title: 'Send Email',
        actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
        classes: 'font-medium-1 font-weight-bold px-3',
        icon: null,
      },
      {
        title: 'Approve',
        actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE,
        classes: 'font-medium-1 font-weight-bold px-3',
        variant: 'success',
        icon: {
          size: '16',
          path: 'LSignatureIconFill',
        },
      },
    ]

    const leftSideButtons = [
      {
        title: 'Change Quote',
        classes: 'font-medium-1 font-weight-bold px-3',
        actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
        icon: {
          size: '16',
          path: 'LLoadIcon',
        },
      },
      {
        title: 'Void',
        actionKey: DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID,
        classes: 'font-medium-1 font-weight-bold px-3 bgRed',
        variant: 'danger',
        icon: {
          size: '16',
          path: 'LNoIcon',
        },
      },
    ]

    return {
      MODULE_NAME,
      EVENT_INFORMATION_TITLE,
      getValueFromGivenObjectByKey,
      eventInformationObjectForRender,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_APPROVE,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_SEND_EMAIL,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_VOID,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_PRINT,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_RELEASE_ASSETS,
      rightSideButtons,
      leftSideButtons,
      storeMutationsList,
      DIRECT_REQUEST_FOR_QUOTE_ACTION_AS_CHANGE_QUOTE,
    }
  },
}
</script>

<style scoped>
.bgRed{
  background-color: #6F1214 !important;
}
</style>
